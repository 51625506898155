// @import "./Styles/main-ltr.scss";
// @import "./Styles/main-rtl.scss";
// @import "Styles/globle.scss";
@import "./App.scss";
// @import "Styles/mixin.scss";

// @import "./Components/Account/Change-password-form/Change-password-form.scss";
// @import "./Components/Account/Edit-address/Edit-address.scss";
// @import "./Components/Account/Edit-personal/Edit-personal.scss";
// @import "./Components/Account/My-Gift-card/My-Gift-card.scss";
// @import "./Components/Account/My-Gift-card/Purchased/Purchased.scss";
// @import "./Components/Account/My-Gift-card/Redeemed/Redeemed.scss";
// @import "./Components/Account/My-order/My-order.scss";
// @import "./Components/Account/My-profile/My-profile.scss";
// @import "./Components/Account/My-profile/Delete-address-popup/Delete-address-popup.scss";
// @import "./Components/Account/My-profile/Make-default-popup/Make-default-popup.scss";
// @import "./Components/Account/My-rewards/My-rewards.scss";
// @import "./Components/Account/My-setting/My-setting.scss";
// @import "./Components/Account/Order-detail/Order-detail.scss";
// @import "./Components/Account/Wish-list-with-item/Wish-list-with-item.scss";
// @import "./Components/Account/Wish-list-without-item/Wish-list-without-item.scss";
// @import "./Components/BannerMiddle/BannerMiddle.scss";
// @import "./Components/BannerMobile/BannerMobile.scss";
// @import "./Components/BannerOverlay/BannerOverlay.scss";
// @import "./Components/Blogs/BlogCards.scss";
// @import "./Components/Blogs/BlogDetail.scss";
// @import "./Components/Blogs/BlogsList.scss";
// @import "./Components/Blogs/ResourceCenter.scss";
// @import "./Components/Brands/Brands.scss";
// @import "./Components/Brands/Model/Modal.scss";
// @import "./Components/Button/Button.style.scss";
// @import "./Components/ComingSoon/ComingSoon.scss";
// @import "./Components/Countrycode/Countrycode.scss";
// @import "./Components/CountryDropdown/LanguageSelect.style.scss";
// @import "./Components/CreateAccount/CreateAccount.scss";
// @import "./Components/Createpassword/Createpassword.scss";
// @import "./Components/CustomerCart/CutomerCart.scss";
// @import "./Components/CustomerReviewSlider/CustomerReviewSlider.scss";
// @import "./Components/CustomHoverDropDown/CustomHoverDropDown.style.scss";
// @import "./Components/CustomRangeSlider/CustomRangeSlider.scss";
// @import "./Components/CutomerProfile/CutomerProfile.scss";
// @import "./Components/DelivaryInfo/DelivaryInfo.style.scss";
// @import "./Components/Desktop-header/Desktop-header.scss";
// @import "./Components/Desktop-header/Header-menu/Header-menu.scss";
// @import "./Components/Desktop-header/Logo-search/Logo-search.scss";
// @import "./Components/Desktop-header/Top-menu/Top-menu.scss";
// // @import "./Components/Direction/Ltr/Ltr.scss";
// // @import "./Components/Direction/Rtl/Rtl.scss";
// @import "./Components/DownloadApp/DownloadApp.style.scss";
// @import "./Components/Dropdown/Dropdown.scss";
// @import "./Components/FaqAccordion/FaqAccordion.scss";
// @import "./Components/FeaturesItem/FeaturesIteam.scss";
// @import "./Components/FilterDropdown/FilterDropdown.scss";
// @import "./Components/FilterListPage/FilterListPage.scss";
// @import "./Components/FilterListPage/FilterCheckData/FilterCheckData.scss";
// @import "./Components/FooterSearchBar/FooterSearchBar.scss";
// @import "./Components/FormComponents/CheckboxLabel/CheckboxLabel.scss";
// @import "./Components/FormComponents/Inc-Dec-box/Inc-Dec-box.scss";
// @import "./Components/FormComponents/InputLabel/InputLabel.scss";
// @import "./Components/FormComponents/RadioCheckboxLabel/RadioCheckboxLabel.scss";
// @import "./Components/FormComponents/RadioLabel/RadioLabel.scss";
// @import "./Components/FormComponents/SelectLabel/SelectLabel.scss";
// @import "./Components/FormComponents/TextareaLabel/TextareaLabel.scss";
// @import "./Components/GiftCard-detail/GiftCard-detail.scss";
// @import "./Components/Header/Header.style.scss";
// @import "./Components/HeaderWrapper/HeaderWrapper.style.scss";
// @import "./Components/HotlineNumber/HotlineNumber.style.scss";
// @import "./Components/ImgCard/ImgCard.scss";
// @import "./Components/InfluencerCard/InfluencerCard.scss";
// @import "./Components/Influncers/Influncers.scss";
// @import "./Components/Influncers/InfluncersList.scss";
// @import "./Components/Label/Label.style.scss";
// @import "./Components/LanguageSelect/LanguageSelect.style.scss";
// @import "./Components/Loader/Loader.style.scss";
// @import "./Components/MainHeader/MainHeader.scss";
// @import "./Components/ManageAppointments/AppointmentDetails/AppointmentDetails.scss";
// @import "./Components/ManageAppointments/AppointmentDetails/AppointmentPopup/AppointmentPopup.scss";
// @import "./Components/ManageAppointments/NoAppointment/NoAppointment.scss";
// @import "./Components/MenuBar/MenuBar.style.scss";
// @import "./Components/MenuBar/MegaMenu/MegaMenu.scss";
// @import "./Components/MenuBar/OfferMenu/OfferMenu.scss";
// @import "./Components/MenuBar/PetcareMenu/petcareMenu.scss";
// @import "./Components/MenuBar/ServiceMenu/ServiceMenu.scss";
// @import "./Components/Mobile-header/Mobile-header.scss";
// @import "./Components/Mobile-header/Side-menu/Side-menu.scss";
// // @import "./Components/MobileFooter/MobileFooter.scss";
// @import "./Components/MobileHeader/MobileHeader.scss";
// @import "./Components/MobileHeader/AutoSerach/AutoSerach.scss";
// @import "./Components/MobileHeader/BurgurMenu/BurgurMenu.scss";
// @import "./Components/MobileHeader/BurgurMenu/MenuList/MenuList.scss";
// @import "./Components/MobileHeader/MobileSearchBar/MobileSearchBar.scss";
// @import "./Components/Model/Modal.scss";
// @import "./Components/multiRangeSlider/multiRangeSlider.scss";
// @import "./Components/MyPets/AddPets/AddPets.scss";
// @import "./Components/MyPets/MyPetsProfile/MyPetsProfile.scss";
// @import "./Components/MyPets/PetDetail/PetDetail.scss";
// @import "./Components/MyPets/PetSuccess/PetSuccess.scss";
// @import "./Components/MyPets/WelcomePet/WelcomePet.scss";
// @import "./Components/Offer/Offer.scss";
// @import "./Components/OfferPage/OfferPage.scss";
// @import "./Components/PrivacyPolicy/PrivacyPolicy.scss";
// @import "./Components/Product-Carousel/Product-Carousel.scss";
// @import "./Components/Product-detail/Compare-similar/Compare-similar.scss";
// @import "./Components/Product-detail/Display-review/Display-review.scss";
// @import "./Components/Product-detail/Frequently-bougth/Frequently-bougth.scss";
// @import "./Components/Product-detail/Product-add/Product-add.scss";
// @import "./Components/Product-detail/Product-add/Personalize-popup/Personalize-popup.scss";
// @import "./Components/Product-detail/Product-add/Product-add.scss";
// @import "./Components/Product-detail/Product-tab/Product-tab.scss";
// @import "./Components/Product-detail/Product-v-carousel/Product-v-carousel.scss";
// @import "./Components/Product-detail/Progress-bar/Progress-bar.scss";
// @import "./Components/Product-detail/Related-product/Related-product.scss";
// @import "./Components/Product-detail/Review-column/Review-column.scss";
// @import "./Components/Product-detail/Reviews/Reviews.scss";
// @import "./Components/Product-detail/Reviews/Rate-Review-Popup/Rate-Review-Popup.scss";
// @import "./Components/Product-detail/Reviews/Rate-Review-Popup/Ratting-box/Ratting-box.scss";
// @import "./Components/Product-detail/Reviews/Reviews-submitted-popup/Reviews-submitted-popup.scss";
// @import "./Components/Product-detail/why-whole-hearted/why-whole-hearted.scss";
// @import "./Components/Product-detail/You-may-like/You-may-like.scss";
// @import "./Components/Product/Product.scss";
// @import "./Components/ProductList/ProductListWithFilter/ProductListWithFilter.scss";
// @import "./Components/ProductList/ProductListWithoutFilter/ProductListWithoutFilter.scss";
// @import "./Components/Resetpassword/Resetpassword.scss";
// @import "./Components/ResetSuccess/ResetSuccess.scss";
// @import "./Components/RewardPoint/RewardPoint.style.scss";
// @import "./Components/SelectCountry/SelectCountry.style.scss";
// @import "./Components/SelectCountryPage/SelectCountryPage.style.scss";
// @import "./Components/SelectCountryPopUp/SelectCountryPopUp.scss";
// @import "./Components/SerachBar/SearchBar.scss";
// @import "./Components/ServicesPage/OurServices.scss";
// @import "./Components/ServicesPage/BookNow/BookNow.scss";
// @import "./Components/ServicesPage/ServiceBanner/ServiceBanner.scss";
// @import "./Components/ServicesPage/ServiceBooking/ServiceBooking.scss";
// @import "./Components/ServicesPage/ServiceBooking/BookingSuccess/BookingSuccess.scss";
// @import "./Components/ServicesPage/ServiceDetail/ServiceDetail.scss";
// @import "./Components/ServicesPage/ServiceFeatures/ServiceFeatures.scss";
// @import "./Components/ServicesPage/ServicesPanel/ServicesPanel.scss";
// @import "./Components/SignIn/SignIn.style.scss";
// @import "./Components/SignInValidate/SignInValidate.scss";
// @import "./Components/SingleBanner/SingleBanner.scss";
// @import "./Components/SliderNav/SliderNav.scss";
// @import "./Components/SmallBanner/SmallBanner.scss";
// @import "./Components/Stores/StoreDetail.scss";
// @import "./Components/Stores/StoreGallery/StoreGallery.scss";
// @import "./Components/Stores/StoreList.scss";
// @import "./Components/Striped-two-column-table/Striped-two-column-table.scss";
// @import "./Components/TermsConditions/TermsConditions.scss";
// @import "./Components/UpdateSuccess/UpdateSuccess.scss";
// @import "./Routes/AboutUs/AboutUs.scss";
// @import "./Routes/AccountPage/AccountPage.scss";
// @import "./Routes/Cart/Cart.scss";
// @import "./Routes/Cart/Order-summary/Order-summary.scss";
// @import "./Routes/Cart/Promo-code/Promo-code.scss";
// @import "./Routes/Cart/Your-cart-item/Move-Remove-popup/Move-Remove-popup.scss";
// @import "./Routes/Cart/Your-cart-item/Your-cart-item.scss";
// @import "./Routes/Cart/Your-reword-point/Your-reword-point.scss";
// @import "./Routes/Checkout/Address/Address.scss";
// @import "./Routes/Checkout/Checkout.scss";
// @import "./Routes/CheckoutWithAddress/Default-address/Default-address.scss";
// @import "./Routes/CheckoutWithAddress/Delivery/Delivery.scss";
// @import "./Routes/CheckoutWithAddress/Order-summary/Order-summary.scss";
// @import "./Routes/CheckoutWithAddress/PaymentMethod/PaymentMethod.scss";
// @import "./Routes/CheckoutWithAddress/Promo-code/Promo-code.scss";
// @import "./Routes/ContactUs/ContactUs.scss";
// @import "./Routes/Empty-cart/Empty-cart.scss";
// @import "./Routes/FaqsPage/Faqs.scss";
// @import "./Routes/Gift-card/Gift-card.scss";
// @import "./Routes/HomePage/HomePage.style.scss";
// @import "./Routes/JoinSuccess/JoinSuccess.scss";
// @import "./Routes/JoinTeam/JoinTeam.scss";
// @import "./Routes/Product-detail/Product-detail.scss";
// @import "./Routes/RewardPage/RewardPage.scss";
// @import "./Routes/ShopByPetPage/ShopByPetPage.scss";
// @import "./Components/Footers/Footers.scss";

body {
  margin: 0;
  scroll-behavior: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  > nav {
    position: relative;
  }
  .route-container {
    min-height: calc(100vh - 678px);
  }
}

//  Globally usable Components Style

// input {
//   border-radius: 23px;
//   width: 100%;
//   height: 46px;
//   padding-left: 20px;
//   padding-right: 20px;
//   outline: none;
//   margin: 3px 0px;
//   // border: 1px solid $input-border-color;
// }
// input::placeholder {
//   // color: $placeholder-color;
//   font-size: 14px;
// }
// input:focus {
//   outline: none;
//   // border: 1px solid $border-color;
// }

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}
