// @import "./Styles/globle";

body {
  // direction: ltr;
  overflow-x: hidden;
  // &.dir-rtl {
  //   direction: rtl;
  // }
}

* {
  outline: none;
  padding: 0;
  margin: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
img {
  width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.m-15 {
  margin-top: 15px;
}

@media (max-width: 991px) {
  .d-md-not {
    display: none;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1224px;
    padding-left: 0;
    padding-right: 0;
  }
  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1600px) {
  .footer-container {
    max-width: 1440px;
    padding-left: auto;
    padding-right: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  [class|="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
}

// simmer Effect

.master-skeleton {
  width: 100%;

  span {
    width: 100%;
  }
}
